import React from 'react';
import Head from 'next/head';
import { DrawerLayout, PageContentLayout } from '@layouts';
import { useIframeHeight } from '@hooks';

const Overview = () => {
  const { iframeHeight } = useIframeHeight();

  return (
    <>
      <Head>
        <title>Overview</title>
        <meta name="description" content="Instacar ledger" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <PageContentLayout
        title="Overview"
        links={[
          {
            title: 'OVERVIEW',
          },
        ]}
        noPadding
      >
        <iframe
          title="Report Section"
          width="100%"
          height={iframeHeight}
          src="https://app.powerbi.com/view?r=eyJrIjoiYzkxZTI1NDEtNTZlZC00YWZmLThjOGUtZmFjYWNiZWU0NWFlIiwidCI6ImVlZDExM2M4LWNlMjItNDQxMC1hMGYxLWJlMjI4NjQ0ZGFkNyIsImMiOjl9"
          allowFullScreen
          frameBorder="0"
          id="resizableIframe"
        />
      </PageContentLayout>
    </>
  );
};

Overview.getLayout = (page) => {
  return <DrawerLayout>{page}</DrawerLayout>;
};

export default Overview;
